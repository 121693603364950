.EmissionsByYearChart .emissions-equity-chart-container {
  height: 200px;
  width: 100%;

  overflow: visible;
}
@media (min-width: 576px) {
  .EmissionsByYearChart .emissions-by-year-chart-container {
    height: 200px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .EmissionsByYearChart .emissions-by-year-chart-container {
    height: 200px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .EmissionsByYearChart .emissions-by-year-chart-container {
    height: 200px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .EmissionsByYearChart .emissions-by-year-chart-container {
    height: 200px;
    width: 100%;
  }
}
