.AuditTrails {
  $primary: #007a5f;
  $white: #fff;
  $gray: #c7c7c7;
  $black: #000;

  .modal-scroll {
    min-height: 100px;
    max-height: 250px;
    overflow: auto;
  }

  .react-datepicker {
    font-size: 14px !important;
    color: $gray !important;
    padding-top: 10px !important;

    &__header {
      background-color: $white !important;
      padding-top: 0px !important;
      border: none !important;
    }

    &__day-name {
      color: $primary !important;
      font-size: 14px !important;
      font-weight: bold;
    }

    &__day {
      color: $black !important;
      font-size: 13px !important;

      &--keyboard-selected,
      &--selected {
        background: $primary !important;
        color: $white !important;
      }
      &--disabled {
        color: $gray !important;
      }
      &--in-selecting-range {
        background: $primary !important;
        color: $white !important;
      }
      &--in-range {
        background: $primary !important;
        color: $white !important;
      }
    }
  }
  .react-datepicker__navigation {
    padding-top: 15px !important;
  }
  .react-datepicker__input-container input {
    border: 1px solid $gray !important;
    border-radius: 3px !important;
    padding: 0.25rem 0 !important;
    text-align: center !important;
    margin: 0 !important;
  }
}
