.custom-toggle-switch {
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    width: 84px;
    height: 30px;
    background-color: #ccc;
    position: relative;
    vertical-align: middle;
    transition: background-color 0.3s ease-in-out;
}

.custom-toggle-switch.checked {
    background-color: #007A5F;
}

.toggle-background {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
}

.custom-toggle-switch.checked .toggle-background {
    transform: translateX(20px);
}

.toggle-button {
    width: 40px;
    height: 26px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.3s ease-in-out;
}

.custom-toggle-switch.checked .toggle-button {
    transform: translateX(20px);
}

.toggle-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
    left: 43px;
    white-space: nowrap;
}

.custom-toggle-switch.checked .toggle-label {
    left: 3px;
}

.custom-toggle-switch.disabled {
    pointer-events: none;
    opacity: 0.5;
}