@keyframes animate-rows {
  from {
    max-height: 0px;
    overflow: hidden;
  }
  to {
    max-height: 100vh;
    transition: max-height 5s;
  }
}

.tbody-animate {
  animation-name: animate-rows;
  animation-duration: 6s;
}

@media (prefers-reduced-motion) {
  .tbody-animate {
    animation: none;
  }
}

@keyframes animate-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-into {
  animation-name: animate-opacity;
  animation-duration: 2s;
  animation-iteration-count: initial;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 1.8em;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 1.3rem 0;
  padding-left: 1.2rem;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #008fd6;
  left: 1.25em;
  width: 1.25em;
  height: 1.25em;
  z-index: 400;
}

.updateModal {
  top: 25%;
}

.updateModalWithComment {
  top: 15%;
}

.modalScroll {
  min-height: 85px;
  max-height: 150px;
  overflow: auto;
}

.reviewBtn {
  color: #b1a800;
  border-color: #b1a800;
}

.reviewBtn:hover {
  background-color: #b1a800;
  color: white;
}

.table-scoll {
  max-height: 55vh;
  display: flex;
}
