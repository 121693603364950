.PortfoliosChart .portfolios-emissions-chart-container {
  height: 240px;
  width: 100%;
  overflow: visible;
}
// @media (min-width: 576px) {
//   .PortfoliosChart .portfolios-emissions-chart-container {
//     height: 256px;
//     width: 100%;
//   }
// }

// @media (min-width: 768px) {
//   .PortfoliosChart .portfolios-emissions-chart-container {
//     height: 256px;
//     width: 100%;
//   }
// }

// @media (min-width: 992px) {
//   .PortfoliosChart .portfolios-emissions-chart-container {
//     height: 256px;
//     width: 100%;
//   }
// }

// @media (min-width: 1200px) {
//   .PortfoliosChart .portfolios-emissions-chart-container {
//     ssions-by-year-chart-container {
//       height: 256px;
//       width: 100%;
//     }
//   }
// }
