@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Two+Tone|Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@import "bootstrap";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background-color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  margin-top: 58px;
}

#root {
  -webkit-transition: 200ms linear;
  -moz-transition: 200ms linear;
  -o-transition: 200ms linear;
  -ms-transition: 200ms linear;
  transition: 200ms linear;
}

.Main {
  display: flex;
  flex-direction: column;
  min-height: 94vh;

  .Footer {
    flex: 0 0 auto;
    margin-bottom: -2px;
  }

  .MainContent,
  .Login,
  .ForgotPassword {
    flex: 1 0 auto;
  }

  .MainHeader {
    flex: 0 0 auto;
  }
}

@media (min-width: 992px) {
  .scroller {
    height: 94vh;
    position: sticky;
    top: 6vh;
    overflow: auto;
  }
}

.material-icons,
.material-icons-outlined {
  vertical-align: middle;
  margin-top: -2px;

  &.md-12 {
    font-size: 12px;
  }

  &.md-14 {
    font-size: 12px;
  }

  &.md-16 {
    font-size: 16px;
  }

  &.md-18 {
    font-size: 18px;
  }

  &.md-20 {
    font-size: 20px;
  }

  &.md-24 {
    font-size: 24px;
  }

  &.md-32 {
    font-size: 32px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }

  &.md-64 {
    font-size: 64px;
  }

  &.md-72 {
    font-size: 72px;
  }

  &.md-84 {
    font-size: 84px;
  }

  &.md-96 {
    font-size: 96px;
  }

  &.md-120 {
    font-size: 120px;
  }
}

.dropdown {
  .dropdown-toggle::after {
    font-family: "Material Icons", sans-serif;
    font-size: 24px;
    color: inherit;
    vertical-align: middle;
    line-height: 1;
    margin: -2px 0 0 0;
    content: "\e5cf";
    border: none;
  }

  .dropdown-toggle.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.navbar-nav {
  .nav-item.dropdown.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.btn:focus,
.btn-close:focus {
  box-shadow: none;
}

.btn-outline-info:hover {
  color: white;
}

.btn-outline-darkOrange:hover {
  color: white;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
}

.modal.left .modal-header,
.modal.right .modal-header,
.modal.left .modal-body,
.modal.right .modal-body,
.modal.right .modal-footer,
.modal.left .modal-footer {
  padding: 32px 32px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: 0px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
  left: 0px;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0px;
}

/* ----- MODAL STYLE ----- */
.modal.left .modal-content,
.modal.right .modal-content {
  border-radius: 0;
  border: none;
}

@media (min-width: 768px) {
  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    width: 480px;
  }

  /*Left*/
  .modal.left.fade .modal-dialog {
    left: 0px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
  }
}

@media (min-width: 992px) {
  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    width: 640px;
  }

  /*Left*/
  .modal.left.fade .modal-dialog {
    width: 0px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
  }
}

.ContentDetails {
  table {
    @extend .table;
    @extend .table-responsive;
    @extend .table-hover;
    @extend .table-sm;
    @extend .table-bordered;
    color: inherit;
  }
}

.alert-info .ContentDetails table {
  @extend .table-info;
  border-color: shift-color($info, -70%);
  color: shift-color($info, 40%);
}

.alert-success .ContentDetails table {
  @extend .table-success;
  border-color: shift-color($success, -70%);
  color: shift-color($success, 40%);
}

.alert-primary .ContentDetails table {
  @extend .table-primary;
  border-color: shift-color($primary, -70%);
  color: shift-color($primary, 40%);
}

/* hiding scroller for input type number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* TABLE - FIXED FIRST COLUMN */
.table.first-column-fixed {
  th:first-child.year-column,
  td:first-child.year-column {
    min-width: 0;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0px;
    background-color: $light;
    box-shadow: inset;
    border-right: 1px solid #dee2e6;
    min-width: 156px;
    max-width: 300px;
    z-index: 100;
  }
}

/* TABLE - FIXED LAST COLUMN */
.table.last-column-fixed {
  th:last-child.year-column,
  td:last-child.year-column {
    min-width: 0;
  }

  th:last-child,
  td:last-child {
    position: sticky;
    right: -2px;
    background-color: $light;
    box-shadow: inset;
    border-right: 1px solid #dee2e6;
    min-width: 80px;
    max-width: 300px;
    z-index: 100;
  }
}

.table.table-head-fixed {
  thead {
    background-color: $light;
    position: sticky;
    top: 0;
    box-shadow: inset 0 -1px 0 black;
  }
}

.table.table-head-top {
  thead {
    z-index: 1000;
  }
}

/** SCROLL TO END OF TABLE **/
.table-scroll-end .table-responsive {
  display: flex;
  flex-direction: row-reverse;
}

/** SCROLL TO END OF TABLE **/
.table .table-sorter {
  cursor: pointer;
  // min-width: 96px;
}

.table-scroller {
  max-height: 400px;
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}
