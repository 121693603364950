@keyframes kf-color-mix {
  0% {
    color: #b5c334;
  }
  100% {
    color: #eb8146;
  }
}

.LocationPin .site-marker {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  opacity: 0.85;
  display: inline-block;
  cursor: pointer;
  animation: kf-color-mix 1s linear forwards paused;
  background: currentColor;
  border: 1px solid #3e3e1e;
}

.map-year-filter {
  z-index: 10000;
  float: left;
  margin: 10px;
}
