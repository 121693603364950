.CarbonIntensityByYearChart .carbon-intensity-chart-container {
  height: 200px;
  width: 100%;

  overflow: visible;
}
@media (min-width: 576px) {
  .CarbonIntensityByYearChart .carbon-intensity-chart-container {
    height: 200px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .CarbonIntensityByYearChart .carbon-intensity-chart-container {
    height: 200px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .CarbonIntensityByYearChart .carbon-intensity-chart-container {
    height: 200px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .CarbonIntensityByYearChart .carbon-intensity-chart-container {
    height: 200px;
    width: 100%;
  }
}
