.MainHeader {
  .navbar-brand {
    img {
      margin-top: -12px;
      margin-bottom: -12px;
      width: 44px;
      filter: brightness(0) invert(1);
    }
  }
  .navbar-nav {
    .nav-item.dropdown.bg-opacity-10:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .material-icons,
  .material-icons-outlined {
    vertical-align: middle;
    margin-top: -2px;
  }
}
