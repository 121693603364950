.SiteEmissionsByCategory .site-emissions-category-chart-container {
  height: 320px;
  width: 100%;
  overflow: visible;
}
@media (min-width: 576px) {
  .SiteEmissionsByCategory .site-emissions-category-chart-container {
    height: 320px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .SiteEmissionsByCategory .site-emissions-category-chart-container {
    height: 320px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .SiteEmissionsByCategory .site-emissions-category-chart-container {
    height: 320px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .SiteEmissionsByCategory .site-emissions-category-chart-container {
    ssions-by-year-chart-container {
      height: 320px;
      width: 100%;
    }
  }
}
