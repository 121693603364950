.TransportDecarbonizationChart .grid-chart-container {
  height: 240px;
  width: 320px;
}

@media (min-width: 576px) {
  .TransportDecarbonizationChart .grid-chart-container {
    height: 320px;
    width: 400px;
  }
}

@media (min-width: 768px) {
  .TransportDecarbonizationChart .grid-chart-container {
    height: 360px;
    width: 480px;
  }
}

@media (min-width: 992px) {
  .TransportDecarbonizationChart .grid-chart-container {
    height: 400px;
    width: 660px;
  }
}

@media (min-width: 1200px) {
  .TransportDecarbonizationChart .grid-chart-container {
    height: 500px;
    width: 800px;
  }
}
