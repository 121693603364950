.WhatsNew {
  .DropDown {
    width: 380px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }
  .DropDownItem {
    padding: 0 !important;
  }

  .divider {
    height: 0.5px;
  }

  .gray {
    color: #828282;
  }

  .w-max {
    width: fit-content;
  }
}
