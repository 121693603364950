.QodScoreChart .org-qod-score-chart-container {
    height: 320px;
    width: 100%;
    overflow: visible;
  }
  @media (min-width: 576px) {
    .QodScoreChart .org-qod-score-chart-container {
      height: 320px;
      width: 100%;
    }
  }
  
  @media (min-width: 768px) {
    .QodScoreChart .org-qod-score-chart-container {
      height: 320px;
      width: 100%;
    }
  }
  
  @media (min-width: 992px) {
    .QodScoreChart .org-qod-score-chart-container {
      height: 320px;
      width: 100%;
    }
  }
  
  @media (min-width: 1200px) {
    .QodScoreChart .org-qod-score-chart-container {
      ssions-by-year-chart-container {
        height: 320px;
        width: 100%;
      }
    }
  }