.SiteDetail {
  .accordion-button {
    color: #006e56;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    margin-top: 2px;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    color: #006e56;
    background-color: #e6f2ef;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }
  .accordion-body {
    background-color: #e6f2ef;
  }
}

.deleteSiteBtn {
  padding-left: 1.43rem;
}
